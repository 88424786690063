import fetch from './common'

/**
 * 测试get接口
 * @param params
 */

//登录
export const loginApi = (params) => fetch('/admin/login/loginByCode', params, "POST")//登录
export const loginOut = (params) => fetch('/admin/login/loginOut', params, "POST")//退出
export const updatePassword = (params) => fetch('admin/user/updatePassword', params, "POST")//退出


//公用
export const selectBrandList = (param) => fetch('/admin/common/brandList', param, "GET")//select品牌列表
export const selectCompanyList = (param) => fetch('/admin/common/storeList', param, "GET")//select门店列表
export const selectStationList = (param) => fetch('/admin/common/getStationList', param, "GET")//select门店列表
export const selectAreaList = (param) => fetch('/admin/common/getAreaList', param, "GET")//select服务区域列表
export const selectUserList = (param) => fetch('/admin/common/getUserList', param, "GET")//select员工列表

//品牌管理
export const brandList = (param) => fetch('/admin/brand/searchBrand', param, "GET")//品牌列表
export const addBrand = (param) => fetch('/admin/brand/createBrand', param, "POST")//新增品牌
export const brandInfo = (param) => fetch('/admin/brand/editBrand', param, "GET")//品牌详情
export const saveEditBrand = (param) => fetch('/admin/brand/updateBrand', param, "POST")//编辑品牌
export const saveBrandStatus = (param) => fetch('/admin/brand/updateBrandStatus', param, "POST")//修改品牌状态
export const createMenu = (param) => fetch('/api/qw/createMenu', param, "GET")//创建企业微信应用菜单
export const updateCallback = (param) => fetch('/api/qw/updateCallback', param, "GET")//更新回调配置

//门店管理
export const companyList = (param) => fetch('/admin/brand/manageStores', param, "GET")//门店列表
export const companyInfo = (param) => fetch('/admin/brand/editStore', param, "GET")//门店详情
export const getAddCompanyInfo = (param) => fetch('/admin/brand/newStores', param, "GET")//获取企微部门信息
export const saveAddCompany = (param) => fetch('/admin/brand/saveStore', param, "POST")//保存门店信息
export const saveEditCompany = (param) => fetch('/admin/brand/updateStore', param, "POST")//保存门店编辑信息
export const delCompany = (param) => fetch('/admin/brand/delStore', param, "POST")//删除门店

//员工管理
export const staffList = (param) => fetch('/admin/user/conditionSearch', param, "GET")//获取员工列表
export const saveStaffStation = (param) => fetch('/admin/user/updateUserStation', param, "POST")//设置员工岗位
export const saveStaffStore = (param) => fetch('/admin/user/updateUserStore', param, "POST")//设置员工权限门店
export const saveStaffArea = (param) => fetch('/admin/user/updateUserArea', param, "POST")//设置员工服务区域
export const openLoginSwitch = (param) => fetch('/admin/user/openLoginAuth', param, "POST")//设置员工登录权限
export const menuList = (param) => fetch('/admin/user/editUserAuth', param, "GET")//获取员工菜单权限
export const saveMenuAuth = (param) => fetch('/admin/user/updateUserAuth', param, "POST")//保存员工菜单权限
export const resetPassword = (param) => fetch('/admin/user/resetPassword', param, "POST")//充值密码
export const getUserList = (param) => fetch('/admin/common/getUserList', param, "GET")//获取门店员工列表


export const getQwMember = (param) => fetch('/admin/user/getQwMember', param, "GET")//同步企微通讯录

//岗位管理
export const stationList = (param) => fetch('/admin/station/stationList', param, "GET")//获取岗位列表
export const getAddStationInfo = (param) => fetch('/admin/station/getBindUser', param, "GET")//获取添加岗位信息
export const saveAddStationInfo = (param) => fetch('/admin/station/addStation', param, "POST")//保存新增岗位信息
export const editAddStationInfo = (param) => fetch('/admin/station/updateStation', param, "POST")//更新岗位信息
export const delAddStationInfo = (param) => fetch('/admin/station/delStation', param, "POST")//删除岗位信息

//区域管理
export const areaList = (param) => fetch('/admin/area/areaList', param, "GET")//获取区域列表
export const addArea = (param) => fetch('/admin/area/addArea', param, "POST")//新增区域
export const updateArea = (param) => fetch('/admin/area/updateArea', param, "POST")//编辑区域
export const saveDelArea = (param) => fetch('/admin/area/delArea', param, "POST")//删除区域
export const getRoomByArea = (param) => fetch('/admin/area/getRoomByArea', param, "GET")//区域下的包厢列表
export const updateBindArea = (param) => fetch('/admin/area/updateBindArea', param, "POST")//更新区域下的包厢列表
export const delAreaRoom = (param) => fetch('/admin/area/delAreaRoom', param, "POST")//删除包厢
export const importRoom = (param) => fetch('/admin/area/importRoom', param, "POST")//导入包厢


//服务区域
export const areaStationUser = (param) => fetch('/admin/userStation/areaStationUser', param, "GET")//获取员工服务区域列表
export const areaTempList = (param) => fetch('/admin/userStation/areaTempList', param, "GET")//获取员工临时服务区域列表
export const getUserAreaList = (param) => fetch('/admin/userStation/getUserAreaList', param, "GET")//获取调整员工临时区域页面信息
export const getBingUserAreaList = (param) => fetch('/admin/area/getAreas', param, "GET")//获取绑定分配区域列表
export const updateAreaTemp = (param) => fetch('/admin/userStation/updateAreaTemp', param, "POST")//保存员工临时调整区域信息
export const delAreaTemp = (param) => fetch('/admin/userStation/delTempArea', param, "POST")//删除员工临时调整区域信息


//被动任务管理
export const taskList = (param) => fetch('/admin/taskPasv/getStoreTaskList', param, "GET")//获取门店被动任务配置列表
export const saveTaskApi = (param) => fetch('/admin/taskPasv/saveStoreTask', param, "POST")//保存门店被动任务配置列表

//计划任务管理
export const planTaskList = (param) => fetch('/admin/taskPlan/getStoreTaskList', param, "GET")// 获取门店计划任务配置列表
export const saveStoreTask = (param) => fetch('/admin/taskPlan/saveStoreTask', param, "POST")// 保存门店计划任务配置
export const getStoreTaskDetail = (param) => fetch('/admin/taskPlan/getStoreTaskDetail', param, "GET")// 获取门店计划任务配置详情
export const updateStoreTask = (param) => fetch('/admin/taskPlan/updateStoreTask', param, "POST")// 编辑门店计划任务配置
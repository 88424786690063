import axios from 'axios'
import qs from 'qs'
import router from '@/router/index'
import { Message, Loading} from 'element-ui'
// globalConfig.baseUrl
/*eslint-disable*/
export default function(url,params,methodType='GET'){
    return new Promise((resolve, reject) => {
        let headers = null
        if(localStorage.getItem('Authorization')){
            headers = {
                'Content-Type':'application/x-www-form-urlencoded',
                'authorization':localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : ''
            }
        }else{
            headers = {
                'Content-Type':'application/x-www-form-urlencoded',
            }
        }
        let datas = {
            url:url,
            method: methodType,
            baseURL:globalConfig.SrvUrl, 
            // timeout: 1000 * 60,
            headers:headers,
            crossDomain:true
        };
        if(methodType=='POST' || methodType=='post'){
            datas['method'] = 'POST';
            datas['data'] = qs.stringify(params);
        }
        else{
            datas['method'] = 'GET';
            datas['params'] = params
        }
        const loading = Loading.service({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        axios.request(datas).then(res=>{
            loading.close();
            if(res.data.return_code === 200){
                resolve(res.data)
            }else{
                if(res.data.return_code === 403){
                    Message.warning('登录过期');
                    setTimeout(()=>{
                        router.replace({
                            path: '/login'
                        });
                        window.localStorage.clear()
                    },500)
                }else{
                    Message.error(res.data.return_msg);
                }
                reject(res.data)
            }
        }).catch(err=>{
            loading.close();
            console.log(2222)
            if(err.message.includes('timeout')){
                // 请求超时
            }
            reject(err)
        }).finally(()=>{
            // 请求结束
        })
    })
}


